/**
 *
 * @module SlideXFreemode *
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

import { SlideXEventManager } from "./SlideXEventManager";
import { SlideXTimer } from "./SlideXTimer";

/**
 * SlideXFreemode handles the option to auto slide the slider
 */
class SlideXFreemode {

    /**
     * @private
     * @type {HTMLElement}
     */
    _moduleWrapper = null;

    /**
     * @private
     *@type {KeenSlider}
     */
    _slider = null;

    /**
     * @private
     * @type {SlideXTimer}
     */
    _timer = null;

    /**
     * @private
     * @type {Boolean}
     */
    _mouseOver = false;

    /**
     * @private
     * @type {SlideXEventManager}
     */
    _events = false;

    /**
     * @private
     * @type {object}
     */
    _animation = null;


    /**
     * @param {HTMLElement} moduleWrapper
     * @param {SlideXEventManager} events
     */
    constructor(moduleWrapper, events) {

        this._moduleWrapper = moduleWrapper;

        this._events = events;

    }

    /**
     * @property {Function} init this function fires the init process of this class
     * @param {KeenSlider} slider
     * @returns {void}
     */
    init(slider) {

        console.log("Freemode init");

        this._slider = slider;

        this._animation = {
            duration: this._slider.options.freemode,
            easing: (t) => t
        };

        this._slider.on("created", () => { this._createdHandler(); });

        this._slider.on("updated", () => { this._updatedHandler(); });

        this._slider.on("animationEnded", () => { this._animationEnded(); });;


    }

    /**
     * @private
     * @property {Function} _createdHandler this function ist fired when the slider fires the created event
     * @returns {void}
     */
    _createdHandler() {

        this._slider.moveToIdx(5, true, this._animation);
    }

    _updatedHandler() {

        this._slider.moveToIdx(this._slider.track.details.abs + 5, true, this._animation);
    }

    _animationEnded() {

        this._slider.moveToIdx(this._slider.track.details.abs + 5, true, this._animation);
    }

    // GETTER SETTER

}

export { SlideXFreemode };