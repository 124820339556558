/**
 * @module SlideXOptionsHelper
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

/**
 * Creates a new SlideXOptionsHelper
 */
class SlideXOptionsHelper {

    /**
     * @private
     * @type {{}}
     */
    _options = {};

    /**
     * @private
     * @type {{}}
     */
    _rawOptions = null;

    /**
     * @private
     *@type {Array<string>}
     */
    _fields = ["autoplay",
        "loop",
        "navigation",
        "pagination",
        "perView",
        "freemode",
        "origin",
        "selector",
        "duration",
        "renderMode",
        "breakpoints",
        "breakpointSM",
        "breakpointMD",
        "breakpointLG"];

    /**
     * @private
     * @type {boolean}
     */
    _loop = false;


    /**
     * @private
     * @type {number}
     */
    _perView = 1;

    /**
     * @private
     * @type {string}
     */
    _origin = null;

    /**
     * @private
     * @type {number}
     */
    _autoplay = null;

    /**
     * @private
     * @type {number}
     */
    _freemode = null;

    /**
     * @private
     * @type {boolean}
     */
    _navigation = null;

    /**
     * @private
     * @type {boolean}
     */
    _pagination = null;

    /**
     * @private
     * @type {number}
     */
    _duration = 500;

    /**
     * @private
     * @type {string}
     */
    _breakpointSM = null;

    /**
     * @private
     * @type {string}
     */
    _breakpointMD = null;

    /**
     * @private
     * @type {string}
     */
    _breakpointLG = null;

    /**
     * @private
     * @type {Function}
     */
    _onready = null;

    /**
     * @private
     * @type {string}
     */
    _renderMode = null;


    /**
     * @param {Element} moduleWrapper
     * @param {{}} [rawOptions] {optional}
     */
    constructor(moduleWrapper, rawOptions) {

        this._moduleWrapper = moduleWrapper;

        this._rawOptions = rawOptions;
    }

    /**
     * @property {Function} crawlOptions will crawl trough the given options (JS/DOM) and collect them
     * @returns {void}
     */
    crawlOptions() {

        this._fields.forEach((field) => {

            this._setField(field);
        });

        //console.log("******************************");
        //console.log("_options", this._options);

        //console.log(this._onready);

        if (this._onready != null) {

            this._onready();
        }
    }

    /**
     * @private
     * @property {Function} _setField Sets the default fields in options
     * @param {string} field
     * @returns {void}
     */
    _setField(field) {

        // console.log("--------------");
        // console.log("field", field);

        let thisField = this['_' + field];

        let attributeField = null;
        attributeField = this._moduleWrapper.getAttribute("data-opt-" + field);

        //console.log("attributeField", attributeField);

        let optionField = null;

        if (this._rawOptions != null) {
            optionField = this._rawOptions[field];
        }

        //console.log("optionField", optionField);

        let tempValue = null;

        if (attributeField != null && attributeField.length > 0) {

            tempValue = attributeField;
        }
        if (optionField != null) {

            tempValue = optionField;
        }

        if (tempValue == null) {

            tempValue = thisField;
        }

        // Setting options

        if (field == "loop" && (tempValue == true || tempValue == "true")) {

            this._options.loop = true;
        }

        if (field == "breakpoints") {

            this._options.breakpoints = {
                "(min-width: 320px)": {},
                "(min-width: 768px)": {},
                "(min-width: 1024px)": {}
            };
        }

        if (field == "selector") {

            let slides = this._moduleWrapper.querySelectorAll(".slidex__slide");

            this._options.selector = slides;
        }

        if (field == "breakpointSM" && tempValue != null) {

            tempValue = JSON.parse(tempValue);

            this._options.breakpoints['(min-width: 320px)'] = tempValue;
        }

        if (field == "breakpointMD" && tempValue != null) {

            tempValue = JSON.parse(tempValue);

            this._options.breakpoints['(min-width: 768px)'] = tempValue;
        }

        if (field == "breakpointLG" && tempValue != null) {

            tempValue = JSON.parse(tempValue);

            this._options.breakpoints['(min-width: 1024px)'] = tempValue;
        }

        if (field == "origin" && tempValue != null && tempValue.length > 0) {

            this._options.slides = this._options.slides || {};
            this._options.slides.origin = tempValue;
        }

        if (field == "duration" && tempValue != null && tempValue.length > 0) {

            this._options.defaultAnimation = this._options.defaultAnimation || {};
            this._options.defaultAnimation.duration = parseInt(tempValue);
        }

        if (field == "autoplay" && tempValue != null && tempValue.length > 0) {

            this._options.autoplay = parseInt(tempValue);
        }

        if (field == "freemode" && tempValue != null && tempValue.length > 0) {

            console.log('freemode option set');

            this._options.freemode = parseInt(tempValue);
        }

        if (field == "navigation" && tempValue != null && tempValue.length > 0) {

            this._options.navigation = (tempValue == "true") ? true : false;
        }

        if (field == "pagination" && tempValue != null && tempValue.length > 0) {

            this._options.pagination = (tempValue == "true") ? true : false;
        }

        if (field == "perView" && tempValue != null) {

            this._options.slides = this._options.slides || {};
            this._options.slides.perView = parseFloat(tempValue);
        }

        if (field == "renderMode") {

            if (tempValue.length > 0) {
                this._options.renderMode = tempValue;
            } else {
                this._options.renderMode = "precision";
            }
        }
    }

    // GETTER SETTER

    /**
     * Get options
     * @returns {{}} options
     */
    get options() {
        return this._options;
    }

    /**
     * Get onready
     * @returns {Function} onready
     */
    get onready() {
        return this._onready;
    }

    /**
     * Set onready
     * @param {Function} callbk
     * @returns {void}
     */
    set onready(callbk) {
        this._onready = callbk;
    }
}

export { SlideXOptionsHelper };