import KeenSlider from 'keen-slider';
import { SlideXAutoplay } from './utils/SlideXAutoplay';
import { SlideXNavigation } from './utils/SlideXNavigation';
import { SlideXOptionsHelper } from './utils/SlideXOptionsHelper';
import { SlideXPagination } from './utils/SlideXPagination';
import { SlideXEventManager } from './utils/SlideXEventManager';
import { SlideXCSS } from './utils/SlideXCSS';
import { SlideXFreemode } from './utils/SlideXFreemode';

window['de'] = window['de'] || {};

window['de']['mdct'] = window['de']['mdct'] || {};

/**
 * Core Class of the SlideX Slider
 */
window['de']['mdct']['SlideX'] = class {

  /**
   * @private
   * @type {HTMLElement|null}
   */
  _moduleWrapper = null;

  /**
   * @private
   * @type {String}
   */
  _id = "";

  /**
   * @private
   * @type {HTMLElement|null}
   */
  _sliderElement = null;

  /**
   * @private
   * @type {KeenSlider|null}
   */
  _slider = null;

  /**
   * @private
   * @type {SlideXOptionsHelper|null}
   */
  _optionsHelper = null;

  /**
  * @private
  * @type {SlideXNavigation|null}
  */
  _navigation = null;

  /**
  * @private
  * @type {SlideXAutoplay|null}
  */
  _autoplay = null;

  /**
  * @private
  * @type {SlideXPagination|null}
  */
  _pagination = null;

  /**
  * @private
  * @type {SlideXEventManager|null}
  */
  _events = null;

  /**
  * @private
  * @type {{}|null}
  */
  _options = null;

  /**
  * @private
  * @type {SlideXCSS|null}
  */
  _css = null;

  /**
  * @private
  * @type {Function|null}
  */
  _createdCallback = null;

  /**
   *
   * @param {HTMLElement} moduleWrapper HTMLELement of the SlideX Wrapper Div
   * @param {{}|null} [options] {optional} SlideX Javascript options
   * @param {Function|null} [createdCallback] {optional} Callback will be fired when the Slider ist created
   */
  constructor(moduleWrapper, options = null, createdCallback = null) {

    this._moduleWrapper = moduleWrapper;

    this._events = new SlideXEventManager();

    this._createdCallback = createdCallback;

    this._id = this._randomString(32);

    //////console.log("id", this._id);

    this._moduleWrapper.id = this._id;

    this._sliderElement = this._moduleWrapper.querySelector(".keen-slider");

    if (options == null) {

      options = {};
    }

    this._optionsHelper = new SlideXOptionsHelper(this._moduleWrapper, options);
    this._optionsHelper.onready = () => { this._optionsReadyHandler(); };
    this._optionsHelper.crawlOptions();
  }

  /**
   * @private
   * @property {Function} _optionsReadyHandler will be fired after the Optionshelper sets all options
   * @returns {void}
   */
  _optionsReadyHandler() {

    this._options = this._optionsHelper.options;

    ////console.log(this._options);

    let additionalFeatures = [];

    // Check for option navigation
    if (Reflect.has(this._options, "navigation") == true && this._options['navigation'] == true) {
      this._navigation = new SlideXNavigation(this._moduleWrapper);
      additionalFeatures.push((slider) => { this._navigation.init(slider) });
    }

    // Check for option pagination
    if (Reflect.has(this._options, "pagination") == true && this._options['pagination'] == true) {
      this._pagination = new SlideXPagination(this._moduleWrapper);
      additionalFeatures.push((slider) => { this._pagination.init(slider) });
    }

    // Check for option autoplay
    if (Reflect.has(this._options, "autoplay") == true) {
      this._autoplay = new SlideXAutoplay(this._moduleWrapper, this._events);
      additionalFeatures.push((slider) => { this._autoplay.init(slider) });
    }

    // Check for option freemode autoplay
    if (Reflect.has(this._options, "freemode") == true) {
      ////console.log('Frremode class injected');
      this._freemode = new SlideXFreemode(this._moduleWrapper, this._events);
      additionalFeatures.push((slider) => { this._freemode.init(slider) });
    }

    ////console.log(additionalFeatures);

    // Create new Slider instance
    this._slider = new KeenSlider(this._sliderElement, this._options, additionalFeatures);

    // Register SlideX events
    this._events.registerEvents(this._slider);

    // Sets the users created callback if set
    if (this._createdCallback != null) {

      this._events.addListener("created", () => { this._createdCallback(); });
    }

    this._events.addListener("animationEnded", () => { this._animationEndedHandler(); });

    // Instanciate the CSS Helper class
    this._css = new SlideXCSS(this._moduleWrapper);

    // Waits for 100ms and fires the created callback if set
    setTimeout(() => {
      this._slider.update();
      this._events.dispatch("created");
    }, 200);

    //this._events.addListener('animationEnded', ()=>{this._slideChangedHandler();});
    //this._events.addListener('animationStopped', ()=>{this._slideChangedHandler();});
  }

  _animationEndedHandler() {

    this._slider.update();
  }

  _slideChangedHandler() {

    this._slider.update();
  }

  /**
   * @property {Function} update the slider update function
   * @returns {void}
   */
  update() {

    this._slider.update();
  }

  _randomString(length) {

    let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let str = '';
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  }

  // GETTER SETTER

  /**
   * Get autoplay
   * @returns {SlideXAutoplay} autoplay
   */
  get autoplay() {
    return this._autoplay;
  }

  /**
   * Get navigation
   * @returns {SlideXNavigation} navigation
   */
  get navigation() {
    return this._navigation;
  }

  /**
   * Get events
   * @returns {SlideXEventManager} events
   */
  get events() {
    return this._events;
  }
}